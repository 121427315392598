html {
  overscroll-behavior-y: contain;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

/*#root{*/
/*  width: var(--vw);*/
/*  height: var(--vh);*/
/*}*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiPickersToolbar-toolbar {
  background-color: transparent !important;
}

.sunrise-indicator-line,
.sunrise-indicator-arrow {
  border-color: #f1d502;
}

.sunset-indicator-line,
.sunset-indicator-arrow {
  border-color: #4202f1;
}

.sunrise-indicator-line,
.sunset-indicator-line {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  border-style: solid;
  border-width: 1px 0 0;
}

.sunrise-indicator-arrow,
.sunset-indicator-arrow {
  position: absolute;
  z-index: 4;
  margin-top: -5px;
  border-style: solid;
  left: 0;
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.fc .fc-more-popover .fc-popover-body {
  max-height: 160px;
  overflow-y: scroll;
}

.fc .fc-popover {
  z-index: 10 !important;
}
.fc {
  color: #5d081c !important;
  --fc-border-color: #5d081c !important;
  --fc-event-border-color: "#fff";
  --fc-event-bg-color: #5d081c !important;
}
.Mui-checked {
  color: #5d081c !important;
}
